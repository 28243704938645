// These must be the first two lines
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import '@platform/ui/dist/index.css';
import 'leaflet/dist/leaflet.css';
import 'react-datepicker/dist/react-datepicker.css';
import buildUrl from 'build-url';
import { createRoot } from 'react-dom/client';
import { App } from './components/App';
import { IntercomProvider } from 'react-use-intercom';
import reportWebVitals from './reportWebVitals';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from './redux/store';
import { initialState } from './redux/state';
import { BrowserRouter, NavigateFunction, useNavigate } from 'react-router-dom';
import Modals from './modals';
import { clearSubscriptionStatusTimestamp } from './utils/subscription-error';
import { Portal } from '@platform/ui-helpers';
import { using } from '@platform/helpers';

const onRedirectCallback = (navigate: NavigateFunction) => (appState?: AppState) => {
  // Clear the last time the status was checked on the localStorage
  clearSubscriptionStatusTimestamp();
  navigate(appState?.returnTo ?? window.location.pathname);
};

const store = configureStore(initialState);

const Root = () => {
  return (
    <Auth0Provider
      domain={Portal.getEnvironment(process.env, 'REACT_APP_AUTH0_DOMAIN')}
      clientId={Portal.getEnvironment(process.env, 'REACT_APP_AUTH0_CLIENT_ID')}
      redirectUri={using(Portal.getEnvironment(process.env, 'REACT_APP_STAGE'), (stage) =>
        stage
          ? buildUrl(window.location.origin, {
              queryParams: { stage },
            })
          : window.location.origin
      )}
      scope="openid profile email"
      useRefreshTokens
      audience={Portal.getEnvironment(process.env, 'REACT_APP_AUTH0_API_IDENTIFIER')}
      onRedirectCallback={onRedirectCallback(useNavigate())}
    >
      <ReduxProvider store={store}>
        <IntercomProvider appId={Portal.getEnvironment(process.env, 'REACT_APP_INTERCOM_APP_ID')}>
          <App />
          <Modals />
        </IntercomProvider>
      </ReduxProvider>
    </Auth0Provider>
  );
};

const root = createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
