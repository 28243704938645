"use strict";
exports.__esModule = true;
exports.ensureDate = exports.getMediumDeliveryDays = void 0;
var types_1 = require("@platform/types");
var assert_unreachable_1 = require("./assert-unreachable");
var getMediumDeliveryDays = function (medium) {
    switch (medium) {
        case types_1.CampaignMedium.POSTCARD:
            return 8;
        case types_1.CampaignMedium.NOTE:
            return 6;
        case types_1.CampaignMedium.SURVEY:
            return 14;
        default:
            return (0, assert_unreachable_1.assertUnreachableSafe)(medium) || 8;
    }
};
exports.getMediumDeliveryDays = getMediumDeliveryDays;
var ensureDate = function (date) { return (typeof date === 'string' ? new Date(date) : date); };
exports.ensureDate = ensureDate;
