import { Constants } from '@platform/app-config';
import { AssignedToCard, ProspectDetail, ProspectDetailProps, ProspectNotes, InlineActivityFeed } from '@platform/ui';
import { useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import {
  deleteProspectTag,
  loadAllTags,
  updateProspectLeadStatus,
  updateProspectScoutedBy,
  updateProspectTags,
  getProspectActivity,
  requestProfundData,
} from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { startMail, stopMail } from '../../utils/prospects';
import { PortalAPI } from '@platform/api';
import { useAuth0 } from '@auth0/auth0-react';
import { LeadStatusType } from '@platform/helpers';
import { HomeownerDataSubscription } from '@platform/types';
import { LoadedProspect } from '../../redux/state/prospects-state';

interface Props {
  prospect: LoadedProspect;
  prospectDataSubscription: HomeownerDataSubscription;
  getAccessToken: GetAccessTokenFunction;
  campaignId: string;
  onDelete: (id: string) => void;
  onExit: () => void;
  deleteInProgress: boolean;
  userId?: number;
  email?: string;
}

const Content = ({
  prospect,
  prospectDataSubscription,
  getAccessToken,
  campaignId,
  onDelete,
  deleteInProgress,
  onExit,
  userId,
  email,
}: Props) => {
  const dispatch = useTypedDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { leadStatuses } = useSelector(Selectors.leadStatuses);
  const { user } = useSelector(Selectors.user);
  const currentTags = useSelector(Selectors.getTags);
  const { data: activityData, isLoading: activityLoading } = useSelector(
    Selectors.prospectActivity(prospect.externalTrackingId)
  );

  const leadStatusSliderData = useMemo(
    () =>
      leadStatuses.map(({ id, value, icon, isMailTrigger }) => ({
        id,
        icon,
        label: value,
        isMailTrigger,
      })),
    [leadStatuses]
  );

  useEffect(() => {
    dispatch(
      getProspectActivity(getAccessToken, {
        externalId: prospect.externalTrackingId,
      })
    );
  }, [prospect.externalTrackingId, dispatch, getAccessToken]);

  const handleLeadStatusUpdate = useCallback(
    async (args: { externalId: string; leadStatusId: number }) =>
      dispatch(updateProspectLeadStatus(getAccessToken, args.leadStatusId, args.externalId, campaignId, userId)),
    [campaignId, dispatch, getAccessToken, userId]
  );

  const onOpenUrl: ProspectDetailProps['onOpenUrl'] = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const handleBoostClicked: ProspectDetailProps['handleBoostClicked'] = useCallback(() => {
    dispatch(AppActions.setBoostIndicatorModal({ isOpen: true }));
  }, [dispatch]);

  const handleUpdateMailStatus: ProspectDetailProps['handleUpdateMailStatus'] = useCallback(
    async ({ action, externalId }) => {
      if (action === 'start') {
        startMail(dispatch, getAccessToken, externalId);
      } else {
        stopMail(dispatch, getAccessToken, externalId);
      }
    },
    [dispatch, getAccessToken]
  );

  const leadStatusMailTriggers = useMemo(() => {
    return leadStatusSliderData.reduce<Record<number, 'start' | 'stop'>>((acc, status) => {
      acc[status.id] = status.isMailTrigger ? 'start' : 'stop';
      return acc;
    }, {});
  }, [leadStatusSliderData]);

  const handleSaveTag = useCallback(
    (args: { externalId: string; tags: { value: string; externalId: string }[] }) => {
      dispatch(
        updateProspectTags(getAccessToken, {
          tags: args.tags,
          externalId: args.externalId,
        })
      );
    },
    [dispatch, getAccessToken]
  );

  const handleDeleteTag = useCallback(
    (tagExternalId: string) => {
      dispatch(deleteProspectTag(getAccessToken, { externalId: prospect.externalTrackingId, tagExternalId }));
    },
    [dispatch, getAccessToken, prospect.externalTrackingId]
  );

  const onOpenContactModal = useCallback(() => {
    dispatch(
      AppActions.setProspectsContactModal({
        prospectId: prospect.externalTrackingId,
      })
    );
  }, [prospect.externalTrackingId, dispatch]);

  const onOpenProspectInfoModal = useCallback(() => {
    dispatch(
      AppActions.setProspectInfoModal({
        prospectId: prospect.externalTrackingId,
      })
    );
  }, [prospect.externalTrackingId, dispatch]);

  const onOpenTagsModal = useCallback(() => {
    dispatch(loadAllTags(getAccessTokenSilently));
    dispatch(
      AppActions.setProspectsTagsModal({
        prospectId: prospect.externalTrackingId,
        tags: prospect.prospectTags,
      })
    );
  }, [dispatch, getAccessTokenSilently, prospect.externalTrackingId, prospect.prospectTags]);

  const onClickViewAllActivity = useCallback(() => {
    dispatch(
      AppActions.setActivityFeedModal({
        externalTrackingId: prospect.externalTrackingId,
      })
    );
  }, [dispatch, prospect.externalTrackingId]);

  const onOpenActivityDetailModal = useCallback(
    (payload: PortalAPI.ProspectAPI.ActivityData<PortalAPI.ProspectAPI.ActivityDataType>) => {
      dispatch(
        AppActions.setActivityDetailsModal({
          ...payload,
          prospectId: prospect.externalTrackingId,
        })
      );
    },
    [dispatch, prospect.externalTrackingId]
  );

  const onAddNote = useCallback(() => {
    dispatch(
      AppActions.setProspectsNotesModal({
        prospectId: prospect.externalTrackingId ?? '',
        content: '',
      })
    );
  }, [dispatch, prospect.externalTrackingId]);

  const onClickAppointment = useCallback(() => {
    dispatch(
      AppActions.setNewAppointmentModal({
        prospectId: prospect.externalTrackingId ?? '',
        appointmentTime: prospect.prospectAppointment?.appointmentTime,
      })
    );
  }, [dispatch, prospect.externalTrackingId, prospect.prospectAppointment]);

  const onRequestProfundData = useCallback(() => {
    dispatch(requestProfundData(getAccessTokenSilently, { externalId: prospect.externalTrackingId }));
  }, [dispatch, getAccessTokenSilently, prospect.externalTrackingId]);

  const onClickUnassign = useCallback(() => {
    dispatch(
      updateProspectScoutedBy(getAccessToken, {
        assignedToId: null,
        externalId: prospect.externalTrackingId,
      })
    );
  }, [dispatch, getAccessToken, prospect.externalTrackingId]);

  const contactProps = useMemo(
    () => ({
      name: prospect.name ?? null,
      phone1: prospect.phoneNumber ?? null,
      phone2: prospect.secondaryPhoneNumber ?? null,
      email: prospect.emailAddress ?? null,
      onClick: onOpenContactModal,
    }),
    [onOpenContactModal, prospect.emailAddress, prospect.name, prospect.phoneNumber, prospect.secondaryPhoneNumber]
  );

  const tagsData: ProspectDetailProps['tagsData'] = useMemo(
    () => ({
      allTags: currentTags,
      prospectTags: prospect.prospectTags,
      onDeleteTag: handleDeleteTag,
      onSaveTag: handleSaveTag,
      onOpenTagsModal,
    }),
    [currentTags, handleDeleteTag, handleSaveTag, onOpenTagsModal, prospect.prospectTags]
  );

  const profundPropertyDataProps: ProspectDetailProps['profundPropertyData'] = useMemo(
    () => ({
      isLoading: prospect.profundPropertyDataLoading ?? false,
      data: prospect.profundPropertyData?.data ?? null,
      attemptedAt: prospect.profundPropertyData?.attemptedAt ?? null,
      onRequestData: onRequestProfundData,
    }),
    [
      onRequestProfundData,
      prospect.profundPropertyData?.attemptedAt,
      prospect.profundPropertyData?.data,
      prospect.profundPropertyDataLoading,
    ]
  );

  const appointmentEmptyStateProps = useMemo(
    () =>
      !prospect.prospectAppointment
        ? { onSetDate: onClickAppointment, onClickTip: () => onOpenUrl(Constants.APPOINTMENT_TIPS_URLS) }
        : null,
    [prospect.prospectAppointment, onClickAppointment, onOpenUrl]
  );

  const appointmentScheduledProps = useMemo(
    () =>
      prospect.prospectAppointment
        ? {
            appointmentTime: prospect.prospectAppointment?.appointmentTime,
            onEdit: onClickAppointment,
            onAddToCalendar: () => null,
            onTurnOnAlerts: () => null,
          }
        : null,
    [prospect.prospectAppointment, onClickAppointment]
  );

  return (
    <ProspectDetail
      isMobileApp={false}
      prospectId={prospect.externalTrackingId}
      isHomeowner={prospect.occupancyData?.homeowner ?? null}
      isNameLoading={false}
      prospectDataSubscription={prospectDataSubscription}
      name={prospect.name ?? null}
      addressLine1={prospect.address.address1}
      addressLine2={`${prospect.address.city}, ${prospect.address.state} ${prospect.address.zip}`}
      mailStatus={prospect.mailStatus}
      impressionCount={prospect.deliveryDrips?.progress ?? 0}
      createdDate={prospect.createdAt}
      isDeleting={deleteInProgress}
      assignedToUserId={prospect.assignedTo?.id ?? null}
      user={user}
      handleUpdateMailStatus={handleUpdateMailStatus}
      handleBoostClicked={handleBoostClicked}
      handleLeadStatusUpdate={handleLeadStatusUpdate}
      handleDelete={onDelete}
      onExit={onExit}
      onOpenUrl={onOpenUrl}
      onOpenProspectInfoModal={onOpenProspectInfoModal}
      outerContainerClasses="mt-4"
      leadStatus={prospect.leadStatus}
      leadStatuses={leadStatusSliderData}
      leadStatusUpdatedAt={prospect.leadStatus?.createdAt ?? null}
      leadStatusMailTriggers={leadStatusMailTriggers}
      leadStatusGridLayout
      contact={contactProps}
      notesComponent={
        <ProspectNotes
          prospectId={prospect.externalTrackingId}
          onAddNote={onAddNote}
          data={activityData}
          onClickTip={() => onOpenUrl(Constants.NOTES_TIPS_URLS)}
          onOpenDetail={onOpenActivityDetailModal}
          isLoading={activityLoading}
          isPortal
        />
      }
      appointmentEmptyState={appointmentEmptyStateProps}
      appointmentScheduled={appointmentScheduledProps}
      tagsData={tagsData}
      profundPropertyData={profundPropertyDataProps}
      impressionsComponent={null}
      // prospect.mailStatus !== ProspectMailStatus.NONE ? (
      //   <ImpressionsInfoCard
      //     onClickTip={() => onClickTip(Constants.IMPRESSIONS_TIPS_URLS)}
      //     onAddAutomation={console.log}
      //     isEmpty={false}
      //     details={getImpressionsDetails}
      //   />
      // ) : null
      assignedToComponent={
        !!prospect.assignedTo ? (
          <AssignedToCard
            onClickAssign={console.log}
            onSendReminder={console.log}
            onClickUnassign={onClickUnassign}
            assignedUser={prospect.assignedTo}
          />
        ) : null
      }
      activityTotal={activityData?.length ?? 0}
      activityComponent={
        <InlineActivityFeed
          prospectId={prospect.externalTrackingId}
          onScheduleAppointment={onClickAppointment}
          showScheduleAppointmentButton={
            prospect.leadStatus?.type === LeadStatusType.APPOINTMENT && !appointmentScheduledProps
          }
          onAddNote={onAddNote}
          onViewAll={onClickViewAllActivity}
          data={activityData}
          isLoading={activityLoading}
          onOpenDetail={onOpenActivityDetailModal}
          isPortal
        />
      }
    />
  );
};

export default Content;
