import { PaperAirplaneIcon, PencilIcon } from '@heroicons/react/20/solid';
import { CampaignMailStatus } from '@platform/types';
import { Avatar, Breadcrumbs, CircularProgress, CsvButton, MailCard, Pills, Spinner } from '@platform/ui';
import { Portal } from '@platform/ui-helpers';
import classNames from 'classnames';
import { orderBy, sum } from 'lodash';
import moment from 'moment';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import DetailsHeaderLoading from './details-header-loading';
import { DetailsHeaderUIProps, SelectedTab } from './types';
import { getIconByName } from './utils';

const DetailsHeaderDesktopUI = ({
  campaign,
  onDownloadReport,
  isLoadingReport,
  deliveryWindow,
  isSuperAdmin,
  title: defaultTitle,
  address,
  onMapSelected,
  onListSelected,
  selectedTab,
  onEditTitle,
  onAssignmentClicked,
  leadStatuses,
}: DetailsHeaderUIProps) => {
  const navigate = useNavigate();
  const titleDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const titleInput = useRef() as MutableRefObject<HTMLInputElement>;
  const [title, setTitle] = useState('');
  const [titleWidth, setTitleWidth] = useState(0);
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [titleIsSaving, setTitleIsSaving] = useState(false);

  const topLeadStatuses = useMemo(() => orderBy(leadStatuses, (ls) => ls.count, 'desc').slice(0, 3), [leadStatuses]);

  useEffect(() => {
    setTitle(defaultTitle ?? '');
  }, [defaultTitle]);

  // Adjust the title input element's width to be the same as
  // the div
  useEffect(() => {
    if (titleDiv.current && !editTitleMode) {
      setTitleWidth(titleDiv.current?.offsetWidth);
    }
  }, [title, editTitleMode]);

  // Auto-focus the input
  useEffect(() => {
    if (titleInput.current && editTitleMode) {
      titleInput.current.focus();
    }
  }, [editTitleMode]);

  const created = useMemo(() => {
    if (!campaign) {
      return null;
    }
    const date = moment(campaign.createdAt);
    const now = moment();
    if (now.year() !== date.year() && now.diff(date, 'months') > 6) {
      return `${date.format('MMM D, YYYY')}`;
    }

    return `${date.format('MMM D [at] h:mma')}`;
  }, [campaign]);

  const handleEditTitle = useCallback(async () => {
    setTitleIsSaving(true);
    try {
      await onEditTitle(title);
      setEditTitleMode(false);
    } catch {}
    setTitleIsSaving(false);
  }, [onEditTitle, title]);

  const onTitleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const onEditTitleSelected = useCallback(() => setEditTitleMode(true), []);

  const onEditTitleCancel = useCallback(() => {
    setEditTitleMode(false);
    setTitle(defaultTitle!);
  }, [defaultTitle]);

  const keyboardHandler: KeyboardEventHandler = useCallback(
    (e) => {
      if (editTitleMode) {
        switch (e.key) {
          case 'Escape': {
            onEditTitleCancel();
            break;
          }
          case 'Enter': {
            handleEditTitle();
            break;
          }
          default:
            break;
        }
      }
    },
    [editTitleMode, handleEditTitle, onEditTitleCancel]
  );

  if (!campaign) {
    return <DetailsHeaderLoading isDesktop />;
  }

  const { targets, leadStatusSummary, mailStatus, program, impressionCount, company } = campaign;

  return (
    <div className="flex flex-col bg-white shadow">
      <div className="flex flex-col px-4 lg:px-10 lg:pt-8 pb-6 lg:pb-8">
        <div className="flex mb-8">
          <Breadcrumbs
            icon={
              <PaperAirplaneIcon
                className="transform -rotate-45 text-gray-400 -mt-0.5 -mr-1.5 flex-shrink-0 h-5 w-5"
                aria-hidden="true"
              />
            }
            pages={[
              {
                name: 'All Campaigns',
                onClick: () => navigate(Portal.getRouteName('/campaigns')),
              },
              {
                name: defaultTitle?.slice(0, 50) ?? 'Campaign',
              },
            ]}
          />
        </div>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-1 items-center">
            {!!targets && (
              <CircularProgress
                total={targets ?? 0}
                current={leadStatusSummary ? sum(Object.values(leadStatusSummary).map((s) => s.count)) : 0}
                size="small"
                variant="info"
              />
            )}
            <div className="flex flex-col ml-2">
              <p className="text-sm font-normal text-gray-500 px-2">Created on {created}</p>
              <div
                ref={titleDiv}
                className={classNames(
                  { hidden: editTitleMode },
                  'flex flex-auto shrink items-center space-x-4 cursor-pointer hover:bg-gray-100 active:bg-blue-50 rounded-md px-2'
                )}
                onClick={onEditTitleSelected}
              >
                <h1 className="text-3xl font-bold  text-gray-900">{title}</h1>
                <PencilIcon className="text-blue-500" height={20} width={20} />
              </div>
              <div className={classNames({ hidden: !editTitleMode }, 'flex flex-wrap items-center rounded-md')}>
                <input
                  value={title}
                  ref={titleInput}
                  style={{ width: `${titleWidth}px` }}
                  className="block px-2 mr-2 border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-3xl font-bold disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  onChange={onTitleChange}
                  onKeyDown={keyboardHandler}
                />

                <div className="flex items-center justify-center space-x-2 rounded-b">
                  <button
                    disabled={titleIsSaving}
                    className="flex-1 bg-blue-500 text-center text-white active:bg-blue-600 font-bold text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-blue-700"
                    onClick={handleEditTitle}
                  >
                    {!titleIsSaving ? 'Save' : <Spinner variant="primary" className="h-4 w-4" />}
                  </button>
                  <button
                    className="flex-1 text-center background-transparent text-gray-500 hover:bg-gray-50 active:bg-gray-100 font-bold text-sm px-2 py-2 rounded outline-none focus:outline-gray-200"
                    type="button"
                    onClick={onEditTitleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <p className="text-sm font-normal text-gray-500 px-2">{address}</p>
            </div>
          </div>
          <div className="flex flex-col ml-3">
            <Pills
              pills={[
                {
                  icon: getIconByName('map', selectedTab === SelectedTab.MAP),
                  text: 'Map',
                  onClick: onMapSelected,
                  isSelected: selectedTab === SelectedTab.MAP,
                },
                {
                  icon: getIconByName('menu', selectedTab === SelectedTab.LIST),
                  text: 'List',
                  onClick: onListSelected,
                  isSelected: selectedTab === SelectedTab.LIST,
                },
              ]}
            />
          </div>
        </div>
        <div className="flex min-h-[98px]">
          <button
            className="flex items-center p-4 border border-solid border-gray-200 rounded-lg mr-2.5 hover:bg-gray-100 active:bg-blue-50"
            onClick={onAssignmentClicked}
          >
            <div className="inline-flex border-4 border-solid border-gray-100 rounded-full">
              <Avatar avatar={{ name: campaign.assignedToUserName }} size="2xl" />
            </div>

            <div className="flex flex-col ml-4">
              <p className="text-xs leading-4 font-semibold text-gray-500 uppercase text-left">Assigned to</p>
              <p className="text-sm leading-5 font-semibold text-gray-900">{campaign.assignedToUserName}</p>
            </div>
          </button>
          {!!targets && (
            <div className="flex items-center p-4 border border-solid border-gray-200 rounded-lg mr-2.5">
              <div className="flex flex-col mr-6 space-y-1">
                <p className="text-xs leading-4 font-semibold text-gray-500 uppercase">{`${targets ?? 0} ${
                  targets > 1 ? 'Prospects' : 'Prospect'
                }`}</p>
                <div className="flex flex-row space-x-2">
                  {topLeadStatuses.map(({ Icon, count }, idx) => (
                    <div className="flex flex-row" key={idx}>
                      <Icon className="flex-shrink-0 h-5 w-5" />
                      <p className="text-sm leading-5 font-semibold text-gray-900 ml-1">{count}</p>
                    </div>
                  ))}
                </div>
                {topLeadStatuses.length !== leadStatuses.length && (
                  <p className="text-xs leading-5 font-semibold text-gray-900 ml-1">
                    ...and {leadStatuses.length - topLeadStatuses.length} more
                  </p>
                )}
              </div>
              <CsvButton
                onClick={() => onDownloadReport(campaign?.externalTrackingId)}
                isDisabled={isLoadingReport}
                isLoading={isLoadingReport}
              />
            </div>
          )}
          {mailStatus !== CampaignMailStatus.NONE && (
            <MailCard
              date={deliveryWindow}
              status={mailStatus}
              description={program?.name}
              impressions={impressionCount}
            />
          )}
          {isSuperAdmin && (
            <div className="flex items-center p-4 border border-solid border-gray-200 rounded-lg ml-2.5">
              <div className="inline-flex border-4 border-solid border-gray-100 rounded-full">
                <Avatar avatar={{ name: company.name }} size="2xl" />
              </div>
              <div className="flex flex-col ml-4">
                <p className="text-xs leading-4 font-semibold text-gray-500 uppercase">Company Name</p>
                <p className="text-sm leading-5 font-semibold text-gray-900">{company.name}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsHeaderDesktopUI;
