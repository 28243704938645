import { EnvelopeIcon, PaperClipIcon, PencilIcon } from '@heroicons/react/20/solid';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { Constants } from '@platform/app-config';
import { assertUnreachableSafe } from '@platform/helpers';
import { CampaignMedium, CampaignStrategy, displayForMedium, TriggerTypes } from '@platform/types';
import { Spinner } from '@platform/ui';
import { Portal } from '@platform/ui-helpers';
import classNames from 'classnames';
import { sum } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { ProgramSelectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { loadPrograms } from '../../redux/thunks';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';

const AutomationCard = (props: {
  heading: string;
  description: string;
  image: string;
  title: string;
  icon: string;
}) => (
  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-24 sm:pt-32 lg:pt-32 group cursor-default">
    <img
      src={props.image}
      alt="Automation"
      className="absolute inset-0 -z-10 h-full w-full object-cover group-hover:scale-110 ease-in-out duration-500 "
    />
    <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/70 " />
    <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

    <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm font-semibold leading-6 text-white">
      <div className="flex items-center gap-x-4">
        <div className="flex gap-x-2.5">
          <img src={props.icon} alt="Icon" className="h-6 w-6 flex-none rounded-full bg-white/10" />

          <p className="mr-8 leading-6 text-yellow-500 text-md uppercase font-bold">{props.title}</p>
        </div>
      </div>
    </div>
    <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
      <span className="absolute inset-0" />
      {props.heading}
    </h3>
    <p className="mt-2 mr-8 text-gray-300">{props.description}</p>
  </article>
);

const automationCards: Parameters<typeof AutomationCard>[0][] = [
  {
    title: 'Notes to Neighbors',
    heading: 'Send mail to neighbors around every appointment or jobsite',
    description: 'Keep your brand front and center wherever your team is running appointments or doing jobs.',
    icon: '/assets/badge-neighbors%402x.png',
    image: '/assets/aerial-neighborhood-stock.jpg',
  },
  {
    title: 'Storm Blast',
    heading: 'Advertise your storm repair capabilities',
    description:
      'Get your brand into neighborhoods hit by storms before your competition with a burst of postcards or handwritten notes.',
    icon: '/assets/badge-storm%402x.png',
    image: '/assets/storm.jpg',
  },
  {
    title: 'Lead Rehash',
    heading: 'Retarget old, cold leads',
    description: 'Follow up with leads that have gone cold with a thoughtful note or series of postcards.',
    icon: '/assets/badge-rehash%402x.png',
    image: '/assets/sales-rep-with-ipad.jpg',
  },
  {
    title: 'Thank You Notes',
    heading: 'Show past customers some love and get referrals',
    description: 'Send a handwritten thank you note to past customers and ask for ratings or referrals.',
    icon: '/assets/badge-thanks%402x.png',
    image: '/assets/dad-with-daughter-on-shoulders.jpeg',
  },
  {
    title: 'Seasonal Marketing',
    heading: 'Send seasonal mail to your customers',
    description:
      'Schedule mail to repeat every year at specific times - spring specials, fall discounts, holiday notes and more!',
    icon: '/assets/badge-seasonal%402x.png',
    image: '/assets/Christmas%20card.webp',
  },
  {
    title: 'Digital Advertising',
    heading: 'Target homeowners with digital ads',
    description: 'Target individual homes with digital display ads using advanced reverse IP address lookup.',
    icon: '/assets/badge-digital%402x.png',
    image: '/assets/digitaladvertising.jpg',
  },
];

export const ProgramSettings = withAuthenticatedPageLayout((props) => {
  const { programs, isLoaded, isLoading } = useSelector(ProgramSelectors.programs);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const goToId = useCallback(
    (id: number) => {
      navigate(generatePath(Portal.getRouteName('/program-settings/:id'), { id: id.toString() }));
    },
    [navigate]
  );

  const cards = useMemo(() => automationCards.map((c, i) => <AutomationCard key={i} {...c} />), []);

  useEffect(() => {
    dispatch(loadPrograms(props.auth0.getAccessTokenSilently));
  }, [dispatch, props.auth0.getAccessTokenSilently]);

  return (
    <div>
      <PageHeader title="Automations" icon={PaperAirplaneIcon} iconHeading="Marketing" />
      <div className="px-4 py-5 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        {isLoading ? (
          <div className="w-full pt-5 text-center">
            <Spinner size="xl" variant="neutral" />
          </div>
        ) : programs.length === 0 && isLoaded ? (
          <div>
            <div className="py-16 sm:py-16">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Add your first automation
                  </h2>
                  <p className="mt-2 text-lg leading-8 text-gray-600">
                    Build more brand awareness with these powerful automations. Schedule a time to add any of these
                    automations to your account.
                  </p>
                  <a href={Constants.MAIL_ONBOARDING_URL} target="_blank" rel="noreferrer">
                    <button
                      type="button"
                      className="sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none mt-4"
                    >
                      <CalendarIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                      Schedule Add-on Setup
                    </button>
                  </a>
                  <p className="mt-4">
                    <a
                      href="https://www.leadscoutapp.com/pricing"
                      target="_blank"
                      className="text-blue-600 hover:text-blue-700 hover:underline font-semibold"
                      rel="noreferrer"
                    >
                      View Add-on Pricing
                    </a>
                  </p>
                </div>
                <div className="mx-auto mt-12 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-12 lg:mx-0 lg:max-w-none xl:grid-cols-2">
                  {cards}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <ul className="flex flex-col">
              {programs.map((program, i, { length }) => {
                const isLastChild = i + 1 === length;
                return (
                  <li
                    key={`program-list-${program.id}`}
                    className={classNames({ 'mb-5': !isLastChild }, 'flex flex-col')}
                  >
                    <div
                      onClick={() => goToId(program.id)}
                      className={classNames(
                        { 'cursor-pointer hover:shadow-md': true },
                        'flex flex-col shadow active:shadow-sm bg-white relative rounded-lg p-6 transition-shadow'
                      )}
                    >
                      <div className="flex flex-col">
                        <div className="flex justify-between grow">
                          <h3 className="text-lg font-semibold text-gray-900 mb-1">
                            {program.name}
                            {program.isDemo ? ' (DEMO)' : ''}
                          </h3>
                          <div className="flex space-x-2 items-center">
                            {!!program.activatedOn && (
                              <div className="text-sm font-normal text-gray-500 hidden sm:block">
                                Updated{' '}
                                {moment(
                                  program.updatedAt > program.activatedOn ? program.updatedAt : program.activatedOn
                                ).format('MMM D, YYYY')}
                              </div>
                            )}
                            <div
                              className={classNames(
                                {
                                  'text-gray-600 bg-gray-200': !program.activatedOn || program.pausedOn,
                                  'text-white bg-green-400': !!program.activatedOn && !program.pausedOn,
                                },
                                'rounded-2xl font-semibold py-1 px-3'
                              )}
                            >
                              {program.activatedOn && !program.pausedOn
                                ? 'Live'
                                : !!program.pausedOn
                                ? 'Paused'
                                : 'Draft'}
                            </div>
                          </div>
                        </div>
                        {program.trigger === TriggerTypes.targetsList && (
                          <p className="text-md font-semibold text-gray-500">Available via CSV Upload</p>
                        )}
                        {program.campaignStrategy === CampaignStrategy.LEAD_MULTIPLIER && (
                          <p className="text-md font-semibold text-gray-500">Available via Zapier Only</p>
                        )}
                        <p className="text-sm font-normal text-gray-500">
                          {Math.ceil(
                            sum(
                              program.sendoutSpecification.map((s) =>
                                s.timing === 'immediate'
                                  ? 0
                                  : s.timing.unit === 'weeks'
                                  ? s.timing.value * 7
                                  : s.timing.unit === 'days'
                                  ? s.timing.value
                                  : assertUnreachableSafe(s.timing.unit)
                              )
                            ) / 7
                          )}{' '}
                          Week Series
                        </p>
                        <div className="flex flex-wrap pt-5">
                          {program.sendoutSpecification.map((s, i, { length }) => (
                            <div key={i} className="flex flex-row items-center space-x-2">
                              {s.medium === CampaignMedium.POSTCARD ? (
                                <div className="flex flex-row items-center space-x-2 bg-indigo-50 border-indigo-100 border-solid border py-1 px-2 pr-1 rounded-3xl text-indigo-900">
                                  <EnvelopeIcon className="h-5 w-5" />
                                  <div className="pr-2">{displayForMedium[s.medium]}</div>
                                </div>
                              ) : s.medium === CampaignMedium.NOTE ? (
                                <div className="flex flex-row items-center space-x-2 bg-red-50 border-red-100 border-solid border py-1 px-2 pr-1 rounded-3xl text-red-900">
                                  <PencilIcon className="h-5 w-5" />
                                  <div className="pr-2">{displayForMedium[s.medium]}</div>
                                </div>
                              ) : s.medium === CampaignMedium.SURVEY ? (
                                <div className="flex flex-row items-center space-x-2 bg-yellow-50 border-yellow-100 border-solid border py-1 px-2 pr-1 rounded-3xl text-yellow-900">
                                  <PaperClipIcon className="h-5 w-5" />
                                  <div className="pr-2">{displayForMedium[s.medium]}</div>
                                </div>
                              ) : (
                                assertUnreachableSafe(s.medium)
                              )}{' '}
                              {length !== i + 1 ? <span className="pr-2"> + </span> : ''}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="py-16 sm:py-16">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Popular Automations</h2>
                  <p className="mt-2 text-lg leading-8 text-gray-600">
                    Build more brand awareness with these powerful automations. Schedule a time to add any of these
                    automations to your account.
                  </p>

                  <a href={Constants.MAIL_ONBOARDING_URL} target="_blank" rel="noreferrer">
                    <button
                      type="button"
                      className="sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none mt-4"
                    >
                      <CalendarIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                      Schedule Add-on Setup
                    </button>
                  </a>
                  <p className="mt-4">
                    <a
                      href="https://www.leadscoutapp.com/pricing"
                      target="_blank"
                      className="text-blue-600 hover:text-blue-700 hover:underline font-semibold"
                      rel="noreferrer"
                    >
                      View Add-on Pricing
                    </a>
                  </p>
                </div>
                <div className="mx-auto mt-12 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-12 lg:mx-0 lg:max-w-none xl:grid-cols-2">
                  {cards}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
