"use strict";
var _a, _b, _c;
exports.__esModule = true;
exports.ProspectMailStatus = exports.CampaignMailStatus = exports.CampaignFilter = exports.displayForTrigger = exports.displayForMedium = exports.displayForStrategy = exports.CampaignMedium = exports.CampaignStrategy = exports.TriggerTypes = void 0;
exports.TriggerTypes = {
    jobSold: 'jobSold',
    lead: 'lead',
    targetsList: 'targetsList'
};
exports.CampaignStrategy = {
    JOBSITE: 'n2n',
    LEAD_MULTIPLIER: 'lead_multiplier',
    STORM_SWATH: 'storm_swath',
    REHASH: 'rehash'
};
exports.CampaignMedium = {
    NOTE: 'note',
    POSTCARD: 'postcard',
    SURVEY: 'survey'
};
exports.displayForStrategy = (_a = {},
    _a[exports.CampaignStrategy.JOBSITE] = 'Jobsite',
    _a[exports.CampaignStrategy.LEAD_MULTIPLIER] = 'Lead Multiplier',
    _a[exports.CampaignStrategy.STORM_SWATH] = 'Storm Swath',
    _a[exports.CampaignStrategy.REHASH] = 'Lead Rehash',
    _a);
exports.displayForMedium = (_b = {},
    _b[exports.CampaignMedium.NOTE] = 'Note',
    _b[exports.CampaignMedium.POSTCARD] = 'Postcard',
    _b[exports.CampaignMedium.SURVEY] = 'Survey',
    _b);
exports.displayForTrigger = (_c = {},
    _c[exports.TriggerTypes.jobSold] = 'Job Sold',
    _c[exports.TriggerTypes.lead] = 'Lead',
    _c[exports.TriggerTypes.targetsList] = 'Target List',
    _c);
exports.CampaignFilter = {
    NEW: 'new',
    RECENT: 'recent',
    HISTORICAL: 'historical'
};
exports.CampaignMailStatus = {
    NONE: 'none',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    SCHEDULED: 'scheduled',
    PENDING: 'pending',
    WAITING: 'waiting'
};
exports.ProspectMailStatus = {
    NONE: 'none',
    OFF: 'off',
    ACTIVE: 'active',
    STOPPED: 'stopped',
    COMPLETED: 'completed',
    SCHEDULED: 'scheduled',
    UNDELIVERABLE: 'undeliverable'
};
