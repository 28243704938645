import { PortalAPI } from '@platform/api';
import { TriggerTypes } from '@platform/types';
import { breakpoints, buildProspectGetExpression, FocusSpecification, getSpriteName } from '@platform/ui';
import { MapUI, Portal } from '@platform/ui-helpers';
import bbox from '@turf/bbox';
import { Feature, FeatureCollection, Point } from 'geojson';
import { GeoJSONSource, LngLatLike, Map as MapboxGLMap, MapMouseEvent, MapTouchEvent, Popup } from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { batch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Actions } from '../../../redux/actions';
import { Selectors } from '../../../redux/selectors';
import { useTypedDispatch } from '../../../redux/state';
import { loadAllTags, loadCampaignDetails, loadCampaignProspects, updateCampaignTitle } from '../../../redux/thunks';
import { withPermissionsRequired } from '../../../utils/with-permissions-required';
import { withAuthenticatedPageLayout } from '../../Layout/authenticated-page';
import { Map } from '../../MapBox';
import DetailsHeader from './details-header';
import { SelectedTab } from './details-header/types';
import styles from './details.module.css';
import ProspectsList from './prospects/prospects-list';

const popup = new Popup({
  closeButton: false,
  closeOnClick: true,
});

const CampaignsDetailsPage = withPermissionsRequired(['canAccessCampaigns'])(
  withAuthenticatedPageLayout(({ auth0: { getAccessTokenSilently } }) => {
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const { id: campaignId } = useParams<{ id: string }>();
    const campaign = useSelector(Selectors.campaignById(campaignId));
    const prospects = useSelector(Selectors.orderedProspectsByCampaignId(campaignId));
    const markers = useSelector(Selectors.prospectMarkersForCampaign(campaignId));
    const { user: loadedUser } = useSelector(Selectors.user);
    const [selectedTab, setSelectedTab] = useState(SelectedTab.MAP);
    const isDesktop = useMediaQuery(breakpoints.lg);

    const onErrorGettingDetails = useCallback(() => {
      navigate(Portal.getRouteName('/campaigns'));
    }, [navigate]);

    useEffect(() => {
      if (campaignId) {
        batch(() => {
          // Load existing or fetch the details for the campaign
          dispatch(loadCampaignDetails(campaignId, getAccessTokenSilently, onErrorGettingDetails));
          // Load existing or fetch the prospects for the campaign
          dispatch(loadCampaignProspects(campaignId, getAccessTokenSilently));
          // Get existing tags to use on the ProspectDetails Modal
          dispatch(loadAllTags(getAccessTokenSilently));
        });
      }
    }, [campaignId, dispatch, getAccessTokenSilently, navigate, onErrorGettingDetails]);

    const onEditTitle = useCallback(
      (title: string) => {
        return dispatch(updateCampaignTitle(campaignId!, title, getAccessTokenSilently));
      },
      [dispatch, getAccessTokenSilently, campaignId]
    );

    const onAssignmentClicked = useCallback(
      () =>
        dispatch(
          Actions.App.setCampaignAssignmentModal({
            isOpen: true,
            campaignId: campaignId!,
            assignedUserId: campaign?.assignedToUserId ?? null,
          })
        ),
      [campaign?.assignedToUserId, dispatch, campaignId]
    );

    const onProspectSelected = useCallback(
      (id: string) => {
        dispatch(
          Actions.App.setProspectsDetailsModal({
            isOpen: true,
            campaignId: campaignId ?? null,
            prospectId: id,
          })
        );
      },
      [campaignId, dispatch]
    );

    const onProspectClicked: (e: MapMouseEvent | MapTouchEvent, map: MapboxGLMap) => void = useCallback(
      (e, map) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: [MapUI.Layers.PROSPECTS],
        });
        if (features && features.length && features[0].layer.id === MapUI.Layers.PROSPECTS) {
          const { id } = features[0];
          if (id) {
            onProspectSelected(id.toString());
          }
        }
      },
      [onProspectSelected]
    );

    const onProspectHover: (e: MapMouseEvent, map: MapboxGLMap) => void = useCallback((e, map) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [MapUI.Layers.PROSPECTS],
      });
      if (features && features.length && features[0].layer.id === MapUI.Layers.PROSPECTS) {
        const { geometry, properties } = features[0] as unknown as typeof markers['features'][number];
        if (geometry.type === 'Point') {
          popup
            .setLngLat(geometry.coordinates.slice() as [number, number])
            .setOffset(12)
            .setHTML(
              `
              <div style="margin: 5px 10px 5px 10px;">
                ${properties.name ? `<strong>${properties.name}</strong><br/>` : ''}
                <div style="font-size: 12px;">${
                  !properties.name ? `<strong>${properties.address1}</strong>` : properties.address1
                }</div>
                <div style="font-size: 12px;">${properties.city}, ${properties.state} ${properties.zip}</div>
                </div>
            `
            )
            .addTo(map);
        }
      }
    }, []);

    const onProspectClusterClicked: (e: MapMouseEvent | MapTouchEvent, map: MapboxGLMap) => void = useCallback(
      (e, map) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: [MapUI.Layers.PROSPECT_CLUSTER],
        });
        const clusterId = features[0].properties?.cluster_id;
        (map.getSource(MapUI.Sources.PROSPECTS) as GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;
          map.easeTo({
            center: (features[0].geometry as Point).coordinates as LngLatLike,
            zoom: zoom,
          });
        });
      },
      []
    );

    const clickHandlers = useMemo(
      () => ({
        [MapUI.Layers.PROSPECT_CLUSTER]: onProspectClusterClicked,
        [MapUI.Layers.PROSPECTS]: onProspectClicked,
      }),
      [onProspectClicked, onProspectClusterClicked]
    );

    const campaignBbox = useMemo(() => {
      if (campaign) {
        const fc: FeatureCollection = {
          type: 'FeatureCollection',
          features: [
            ...(campaign.triggerLocation
              ? [
                  {
                    type: 'Feature',
                    properties: {},
                    geometry: campaign.triggerLocation,
                  } as Feature,
                ]
              : []),
            ...('shape' in campaign
              ? [
                  {
                    type: 'Feature',
                    properties: {},
                    geometry: campaign.shape.features[0].geometry,
                  } as Feature,
                ]
              : []),
            ...(campaign.stats?.boundingBox
              ? [{ type: 'Feature', properties: {}, geometry: campaign.stats.boundingBox } as Feature]
              : []),
          ],
        };

        const box = fc.features.length ? bbox(fc) : null;
        if (box) {
          const [a, b, c, d] = box;
          return [
            [a, b],
            [c, d],
          ] as [[number, number], [number, number]];
        }
      }
    }, [campaign]);

    const boundingBox: FocusSpecification | null = useMemo(
      () =>
        campaignBbox
          ? {
              type: 'bounds',
              bounds: campaignBbox,
              focal: 'campaign',
              focalId: '',
              paddingBottom: isDesktop ? 0 : 300,
              maxZoom: 18,
            }
          : null,
      [campaignBbox, isDesktop]
    );

    const triggerLocation: Feature<Point, { address1: string; city: string; state: string; zip: string }> | null =
      useMemo(() => {
        const details =
          campaign?.trigger === TriggerTypes.jobSold || campaign?.trigger === TriggerTypes.lead
            ? (campaign.triggerDetails as PortalAPI.CampaignAPI.CampaignResponseForTriggerType['jobSold' | 'lead'])
            : null;

        if (details) {
          return campaign?.triggerDetails
            ? {
                type: 'Feature',
                properties: {
                  address1: details.address1,
                  city: details.city,
                  state: details.state,
                  zip: details.zip,
                },
                geometry: details.location,
              }
            : null;
        }
        return null;
      }, [campaign?.trigger, campaign?.triggerDetails]);

    const onMapLoaded = useCallback(
      (map: MapboxGLMap) => {
        map.on('mouseenter', MapUI.Layers.PROSPECTS, (e) => {
          onProspectHover(e, map);
        });
        map.on('mouseleave', MapUI.Layers.PROSPECTS, (e) => {
          popup.remove();
        });

        map.on('mouseenter', MapUI.Layers.CAMPAIGN_TRIGGER, (e) => {
          if (e.features) {
            const coordinates = (e.features[0].geometry as Point).coordinates.slice() as [number, number];
            const properties = e.features[0].properties as NonNullable<typeof triggerLocation>['properties'];

            popup
              .setLngLat(coordinates)
              .setOffset(12)
              .setHTML(
                `<div style="margin: 5px 10px 5px 10px;">
                <strong>Campaign address</strong><br/>
                <div style="font-size: 12px;">${properties.address1}</div>
                <div style="font-size: 12px;">${properties.city}, ${properties.state} ${properties.zip}</div>
                </div>`
              )
              .addTo(map);
          }
        });
        map.on('mouseleave', MapUI.Layers.CAMPAIGN_TRIGGER, (e) => {
          popup.remove();
        });
      },
      [onProspectHover]
    );

    return (
      <>
        <DetailsHeader
          campaign={campaign ?? null}
          isSuperAdmin={!!loadedUser && !!loadedUser.isSuperAdmin}
          onListSelected={() => setSelectedTab(SelectedTab.LIST)}
          onMapSelected={() => setSelectedTab(SelectedTab.MAP)}
          selectedTab={selectedTab}
          onEditTitle={onEditTitle}
          onAssignmentClicked={onAssignmentClicked}
        />
        {selectedTab === SelectedTab.MAP ? (
          <div className="flex flex-col">
            {campaign && campaign.stats ? (
              <div className={`flex flex-col relative ${styles.mapContainer}`}>
                <Map clickHandlers={clickHandlers} boundingBox={boundingBox} onMapLoaded={onMapLoaded}>
                  <Source
                    type="geojson"
                    id={MapUI.Sources.CAMPAIGN_SHAPES}
                    data={'shape' in campaign ? campaign.shape : undefined}
                  >
                    <Layer
                      id={MapUI.Layers.CAMPAIGN_SHAPE_AREA}
                      type="fill"
                      paint={{
                        'fill-color': '#3388FF',
                        'fill-opacity': 0.25,
                      }}
                    />
                    <Layer
                      id={MapUI.Layers.CAMPAIGN_SHAPE_BORDER}
                      type="line"
                      paint={{
                        'line-color': '#3388FF',
                        'line-width': 3,
                      }}
                    />
                  </Source>
                  <Source
                    type="geojson"
                    id={MapUI.Sources.PROSPECTS}
                    promoteId="id"
                    data={markers}
                    cluster
                    clusterRadius={15}
                  >
                    <Layer
                      id={MapUI.Layers.PROSPECTS}
                      type="symbol"
                      filter={['!', ['has', 'point_count']]}
                      layout={{
                        'icon-size': isDesktop ? 0.8 : 0.61,
                        'icon-allow-overlap': true,
                        'icon-anchor': 'center',
                        'icon-image': [
                          'case',
                          ['==', buildProspectGetExpression('new'), true],
                          getSpriteName('circle-red-500-selected'),
                          buildProspectGetExpression('icon'),
                        ],
                      }}
                    />
                    <Layer
                      id={MapUI.Layers.PROSPECT_CLUSTER}
                      type="circle"
                      filter={['has', 'point_count']}
                      paint={{
                        'circle-color': '#EF4444',
                        'circle-radius': 18,
                        'circle-stroke-color': '#EF4444',
                        'circle-stroke-width': [
                          'interpolate',
                          ['linear'],
                          buildProspectGetExpression('point_count'),
                          0,
                          2,
                          3,
                          5,
                          10,
                          15,
                        ],
                        'circle-stroke-opacity': 0.5,
                      }}
                    />

                    <Layer
                      id={MapUI.Layers.PROSPECT_CLUSTER_LABEL}
                      type="symbol"
                      filter={['>', buildProspectGetExpression('point_count'), 1]}
                      layout={{
                        'text-field': buildProspectGetExpression('point_count'),
                        'text-font': ['Open Sans Bold'],
                        'text-size': 14,
                      }}
                      paint={{
                        'text-color': 'white',
                      }}
                    />
                  </Source>
                  <Source type="geojson" data={triggerLocation ?? undefined}>
                    <Layer
                      id={MapUI.Layers.CAMPAIGN_TRIGGER}
                      type="symbol"
                      layout={{
                        'icon-image': getSpriteName('hammer-light-blue-600'),
                        'icon-anchor': 'center',
                        'icon-size': isDesktop ? 0.8 : 0.61,
                      }}
                    />
                  </Source>
                </Map>
              </div>
            ) : (
              <div className={`${styles.mapContainer} bg-gray-200 w-full block animate-pulse`}></div>
            )}
          </div>
        ) : (
          <ProspectsList prospects={prospects} onClick={onProspectSelected} isDesktop={isDesktop} />
        )}
      </>
    );
  })
);

export default CampaignsDetailsPage;
