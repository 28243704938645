import { Constants } from '@platform/app-config';
import { getLeadStatusIcon, LeadStatusIcon } from '@platform/helpers';
import { CampaignSettingsForStrategy } from '@platform/model';
import { CampaignStrategy, TriggerTypes } from '@platform/types';
import { Utils } from '@platform/ui-helpers';
import { FeatureCollection, Point } from 'geojson';
import { sortBy } from 'lodash';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { MailDeliveryType } from '../../types/campaign';
import { AddressSuggestion } from '../actions/address-lookup-actions';
import { LoadedCampaign } from '../actions/campaign-actions';
import { LoadedProgram } from '../actions/program-actions';
import { State } from '../state';
import { LoadedProspect } from '../state/prospects-state';
import { UserState } from '../state/user-state';

/* App Selectors */

export const AppSelectors = {
  notifications: (state: State) => state.App.notifications,
  isImpersonating: (state: State) => !!state.App.companyImpersonation?.id,
  subscriptionLastChecked: (state: State) => state.App.subscriptionStatus.lastChecked,
  subscriptionStatus: (state: State) => state.App.subscriptionStatus.status,
  subscriptionStatusIsLoading: (state: State) => state.App.subscriptionStatus.isLoading,
};

/* Modals Selectors */

export const ModalsSelectors = {
  getProspectsDetailsModal: (state: State) => state.App.prospectsDetailsModalIsOpen,
  getProspectsTagsModal: (state: State) => state.App.prospectsTagsModalIsOpen,
  getTeamInviteModalIsOpen: (state: State) => state.App.teamInviteModalIsOpen,
  getCampaignAssignmentModalIsOpen: (state: State) => state.App.campaignAssignmentModal,
  getCompanyInviteModalIsOpen: (state: State) => state.App.companyInviteModalIsOpen,
  getSubscriptionErrorModalIsOpen: (state: State) => state.App.subscriptionErrorModalIsOpen,
  getProspectsNotesModal: (state: State) => state.App.prospectsNotesModalIsOpen,
  getDuplicateAddressModal: (state: State) => state.App.duplicateAddressModalIsOpen,
  getNewAppointmentModal: (state: State) => state.App.prospectsNewAppointmentModalIsOpen,
  getEditTagModal: (state: State) => state.App.editTagModalIsOpen,
  getCreateTagModal: (state: State) => state.App.createTagModalIsOpen,
  getHideTagModal: (state: State) => state.App.hideTagModalIsOpen,
  getDeleteTagModal: (state: State) => state.App.deleteTagModalIsOpen,
  getActivityFeedModal: (state: State) => state.App.activityFeedModalIsOpen,
  getActivityDetailsModal: (state: State) => state.App.activityDetailModal,
};

/* User Selectors */

export const UserSelectors = {
  user: (state: State) => (state.User.isLoaded ? (state.User as Required<UserState>) : (state.User as UserState)),
  permissions: (state: State) => state.User.user?.permissions,
  company: (state: State) => state.Company,
  birdseyeCompanies: (state: State) => state.Company.all,
  canAccessMailPrograms: (state: State) => !!state.User.user?.permissions.canAccessMailPrograms,
  hasPrograms: (state: State) => !!state.Company.hasPrograms,
};

/* Dashboard Selectors */
export const DashboardSelectors = {
  selectedDateFilter: (state: State) => state.Dashboard.selectedFilter,
  leadStatusActivity: (state: State) => state.Dashboard.leadStatusActivity,
  impressions: (state: State) => state.Dashboard.impressions,
  tagActivity: (state: State) => state.Dashboard.tagActivity,
};

/* Campaign Selectors */

export const CampaignSelectors = {
  allCampaigns: createSelector(
    (state: State) => state.Campaigns,
    (c) => ({
      ...c,
      campaigns: c.campaignList.map((id) => c.campaigns[id]),
    })
  ),
  campaignMap: createSelector(
    (state: State) => state.CampaignsMap,
    (map) => ({
      ...map,
      campaigns: Object.keys(map.campaigns).map((key) => ({
        ...map.campaigns[key],
        tooltip: Utils.getCampaignJobTooltip(map.campaigns[key]),
      })),
    })
  ),
  campaignById:
    (externalId?: string) =>
    (state: State): LoadedCampaign | null => {
      const campaigns = state.Campaigns;
      if (!externalId || !campaigns.campaigns[externalId]) {
        return null;
      }
      return campaigns.campaigns[externalId] as LoadedCampaign;
    },
  watchedCampaigns: (state: State) => state.Campaigns.watchCampaigns,
};

/* Campaign Prospects Selectors */
export const CampaignProspectsSelectors = {
  prospectById: (externalId?: string | null) => (state: State) =>
    externalId ? state.Prospects.prospects[externalId] : null,
  prospectsByCampaignId: (campaignId?: string | null) =>
    createSelector(
      (state: State) => state.Prospects.prospects,
      (state: State) => state.Prospects,
      (prospects, campaignProspects) =>
        !campaignId || !campaignProspects.prospectsByCampaign[campaignId]
          ? {
              isLoaded: true,
              prospects: {},
              totalRecords: 0,
            }
          : {
              isLoaded: !!campaignProspects.prospectsByCampaign[campaignId],
              prospects: campaignProspects.prospectsByCampaign[campaignId].ids.reduce<
                Record<string, LoadedProspect & { idx: number; nextId: string; prevId: string }>
              >((acc, id, idx, ids) => {
                acc[id] = {
                  ...prospects[id],
                  idx,
                  nextId: idx < ids.length - 1 ? ids[idx + 1] : ids[0],
                  prevId: idx > 0 ? ids[idx - 1] : ids[ids.length - 1],
                };
                return acc;
              }, {}),
              totalRecords: campaignProspects.prospectsByCampaign[campaignId].totalRecords ?? 0,
            }
    ),
  orderedProspectsByCampaignId: (campaignId?: string | null) =>
    createSelector(
      (state: State) => state.Prospects.prospects,
      (state: State) => state.Prospects,
      (prospects, campaignProspects) =>
        !campaignId || !campaignProspects.prospectsByCampaign[campaignId]
          ? {
              isLoaded: true,
              prospects: [],
              totalRecords: 0,
            }
          : {
              isLoaded: !!campaignProspects.prospectsByCampaign[campaignId],
              prospects: campaignProspects.prospectsByCampaign[campaignId].ids.map((id) => prospects[id]),
              totalRecords: campaignProspects.prospectsByCampaign[campaignId].totalRecords ?? 0,
            }
    ),

  prospectMarkersForCampaign: (campaignId?: string | null) =>
    createSelector(
      (state: State) => (campaignId ? state.Prospects.prospectsByCampaign[campaignId]?.ids : null),
      (state: State) => state.Prospects.prospects,
      (ids, prospects) =>
        ({
          type: 'FeatureCollection',
          features: (ids ?? []).map((id) => {
            const {
              leadStatus,
              location,
              address: { address1, city, state, zip },
              name,
            } = prospects[id];
            return {
              geometry: location,
              type: 'Feature',
              properties: {
                id,
                icon: getLeadStatusIcon(leadStatus?.icon),
                address1,
                city,
                state,
                zip,
                ...(name ? { name } : {}),
              },
            };
          }),
        } as FeatureCollection<
          Point,
          { icon: LeadStatusIcon; address1: string; city: string; state: string; zip: string; name?: string }
        >)
    ),
};

/* Address Selectors */
export const AddressSelectors = {
  currentAddressSearch: (state: State) => state.AddressLookups.search.currentSearch,
  addressSuggestionsForSearch: (search: string) => (state: State) =>
    state.AddressLookups.search.suggestions[search] as AddressSuggestion[] | undefined,
  addressSuggestionsForCurrentSearch: createSelector(
    (state: State) => state.AddressLookups.search,
    (l) => ({
      ...l,
      suggestions: l.suggestions[l.currentSearch] ?? [],
    })
  ),
  addressLookup: (state: State) => state.AddressLookups.lookup,
};

/* Program Selectors */
const programs = createSelector(
  (state: State) => state.Programs,
  (p) => ({
    ...p,
    programs: Object.values(p.programs),
  })
);
const activePrograms = createSelector(
  (state: State) => state.Programs,
  (p) => ({
    ...p,
    programs: Object.values(p.programs).filter((p) => p.isVisible),
  })
);

const isN2nProgram = (p: LoadedProgram) =>
  p.campaignStrategy === CampaignStrategy.JOBSITE && p.trigger === TriggerTypes.jobSold;

const activeN2nPrograms = createSelector(activePrograms, (p) => ({
  ...p,
  programs: sortBy(Object.values(p.programs).filter(isN2nProgram), (p) => p.name),
}));
const allN2nPrograms = createSelector(programs, (p) => ({
  ...p,
  programs: sortBy(Object.values(p.programs).filter(isN2nProgram), (p) => p.name),
}));
export const ProgramSelectors = {
  programs,
  activePrograms,
  activeN2nPrograms,
  allN2nPrograms,
};

export const LeadStatusSelectors = {
  leadStatuses: createSelector(
    (state: State) => state.LeadStatus,
    (p) => ({
      isLoading: p.isLoading,
      isLoaded: p.isLoaded,
      leadStatuses: p.order.map((id) => p.leadStatuses[id]),
    })
  ),
  leadStatusMap: createSelector(
    (state: State) => state.LeadStatus,
    (p) => ({
      isLoading: p.isLoading,
      isLoaded: p.isLoaded,
      leadStatuses: p.leadStatuses,
    })
  ),
  leadStatusWithId: (id: number) => (state: State) => state.LeadStatus.leadStatuses[id] ?? null,
};

export const TeamSelectors = {
  team: (state: State) => state.Team,
};

/* Campaign Creator Selectors */
const getProspectCount = (state: State) => {
  const { prospectCount, prospectTotalCount, mailProgramId, isMailProgramEnabled, mailDeliveryType } =
    state.CampaignCreator;
  if (mailDeliveryType !== MailDeliveryType.SCHEDULE || !isMailProgramEnabled) {
    return 0;
  }

  if (prospectCount !== null && prospectCount <= prospectTotalCount) {
    return Math.min(prospectCount, Constants.DEFAULT_PROSPECT_COUNT_LIMIT);
  } else {
    return Math.min(
      mailProgramId !== null
        ? (state.Programs.programs[mailProgramId].campaignSettings as CampaignSettingsForStrategy['n2n'])
            .numberOfNeighbors ?? 20
        : 20,
      Math.min(prospectTotalCount, Constants.DEFAULT_PROSPECT_COUNT_LIMIT)
    );
  }
};

export const CampaignCreatorSelectors = {
  getProspectTotalCount: (state: State) => state.CampaignCreator.prospectTotalCount,
  getProspectCount,
  getProspectCountStep: (state: State) => state.CampaignCreator.prospectCountStep,
  getProspectCountResetShape: (state: State) => state.CampaignCreator.onResetShape,
  getMailProgramId: (state: State) => state.CampaignCreator.mailProgramId,
  getIsMailProgramEnabled: (state: State) => state.CampaignCreator.isMailProgramEnabled,
  getSalesRepId: (state: State) => state.CampaignCreator.salesRepId,
  getIsSubmitting: (state: State) => state.CampaignCreator.isSubmitting,
  getMapInstructionStep: (state: State) => state.CampaignCreator.mapInstructionStep,
  getCampaignTitle: (state: State) => state.CampaignCreator.campaignTitle,
  getMailDeliveryType: (state: State) => state.CampaignCreator.mailDeliveryType,
  getMailDeliveryDate: (state: State) => state.CampaignCreator.mailDeliveryDate,
  getForm: createSelector(
    (state: State) => state.CampaignCreator,
    getProspectCount,
    (form, prospectCount) => ({
      programId: form.mailProgramId,
      assignedToUserId: form.salesRepId!,
      mailDeliveryType: form.mailDeliveryType,
      programStartDate: form.mailDeliveryDate,
      campaignTitle: form.campaignTitle,
      numberOfNeighbors: (form.isMailProgramEnabled ? prospectCount : 0) ?? 0,
      polygon: form.polygon,
      isMailProgramEnabled: form.isMailProgramEnabled,
    })
  ),
  getEnableCampaignCreation: createSelector(
    ProgramSelectors.activeN2nPrograms,
    ProgramSelectors.allN2nPrograms,
    AddressSelectors.addressLookup,
    getProspectCount,
    (state: State) => state.CampaignCreator,
    (
      { programs: activePrograms, isLoaded: programsLoaded },
      { programs: allPrograms },
      { address },
      prospectCount,
      {
        isSubmitting,
        mailDeliveryDate,
        salesRepId,
        isMailProgramEnabled,
        campaignTitle,
        mailProgramId,
        polygon,
        prospectTotalCount,
        mailDeliveryType,
      }
    ) => {
      if (
        !!address &&
        !isSubmitting &&
        polygon !== null &&
        salesRepId !== null &&
        campaignTitle !== null &&
        campaignTitle.length > 0 &&
        (allPrograms.length === 0 || isMailProgramEnabled !== null) &&
        (prospectCount === 0 || (prospectCount > 0 && prospectTotalCount <= Constants.DEFAULT_PROSPECT_COUNT_LIMIT))
      ) {
        if (!isMailProgramEnabled) {
          return true;
        } else {
          return (
            programsLoaded &&
            (activePrograms.length > 0 ? !!mailProgramId : true) &&
            mailDeliveryDate !== null &&
            mailDeliveryType !== null
          );
        }
      }

      return false;
    }
  ),
};

export const ProspectSelectors = {
  prospectAppointmentTime: (externalId: string) => (state: State) =>
    state.Prospects.prospects[externalId].prospectAppointment?.appointmentTime,
  prospectActivity: (externalId: string) => (state: State) => state.Prospects.activity[externalId] ?? [],
};

export const TagsSelectors = {
  getTagsState: (state: State) => {
    const { isLoading, isLoaded } = state.Tags;
    return {
      isLoading,
      isLoaded,
    };
  },
  getTags: createSelector(
    (state: State) => state.Tags,
    (t) => t.data.list.map((id) => t.data.tags[id])
  ),
  getTagByExternalId: (externalId: string) => (state: State) => {
    return state.Tags.data.tags[externalId];
  },
};

export const Selectors = {
  ...AddressSelectors,
  ...AppSelectors,
  ...CampaignCreatorSelectors,
  ...CampaignProspectsSelectors,
  ...CampaignSelectors,
  ...DashboardSelectors,
  ...LeadStatusSelectors,
  ...ModalsSelectors,
  ...ProgramSelectors,
  ...TeamSelectors,
  ...UserSelectors,
  ...ProspectSelectors,
  ...TagsSelectors,
};

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector;
