import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { CampaignMailStatus } from '@platform/types';
import { Avatar, CircularProgress, CsvButton, MailCard, Tabs } from '@platform/ui';
import { Portal } from '@platform/ui-helpers';
import classNames from 'classnames';
import { sum } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import DetailsHeaderLoading from './details-header-loading';
import { DetailsHeaderUIProps, SelectedTab } from './types';
import { getIconByName } from './utils';

const DetailsHeaderMobileUI = ({
  campaign,
  onDownloadReport,
  isLoadingReport,
  deliveryWindow,
  title,
  address,
  onMapSelected,
  onListSelected,
  selectedTab,
  onAssignmentClicked,
}: DetailsHeaderUIProps) => {
  const created = useMemo(() => {
    if (!campaign) {
      return null;
    }
    const date = moment(campaign.createdAt);
    const now = moment();
    if (now.year() !== date.year() && now.diff(date, 'months') > 6) {
      return `${date.format('MMM D, YYYY')}`;
    }
    return `${date.format('MMM D [at] h:mma')}`;
  }, [campaign]);

  if (!campaign) {
    return <DetailsHeaderLoading isDesktop={false} />;
  }

  const { targets, leadStatusSummary, mailStatus, program, impressionCount } = campaign;

  return (
    <div className="flex flex-col bg-white shadow">
      <div className="flex flex-col px-4 pb-6">
        <div className="flex space-x-1 mb-4 items-center text-gray-400">
          <ChevronLeftIcon className="flex-shrink-0 h-4 w-4" aria-hidden="true" />
          <Link to={Portal.getRouteName('/campaigns')} className="text-sm">
            Back
          </Link>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="text-sm font-normal text-gray-500">Created on {created}</p>
              <h1 className="text-2xl font-bold leading-normal text-gray-900 sm:truncate mb-1">{title}</h1>
              <p className="text-sm font-normal text-gray-500">{address}</p>
            </div>
          </div>
          <div className="flex flex-col ml-3">
            <CsvButton
              onClick={() => onDownloadReport(campaign?.externalTrackingId)}
              isDisabled={isLoadingReport}
              isLoading={isLoadingReport}
            />
          </div>
        </div>
        <div className="flex items-center mb-4">
          <button className="flex items-center" onClick={onAssignmentClicked}>
            <Avatar avatar={{ name: campaign.assignedToUserName }} size="medium" />
            <p className="text-sm leading-5 font-semibold text-gray-900 ml-2">{campaign.assignedToUserName}</p>
          </button>
          <div className={classNames({ hidden: !targets }, 'flex ml-auto')}>
            <CircularProgress
              total={targets ?? 0}
              current={leadStatusSummary ? sum(Object.values(leadStatusSummary).map((s) => s.count)) : 0}
              size="small"
              variant="info"
            />
          </div>
        </div>
        {mailStatus !== CampaignMailStatus.NONE && (
          <div className="flex flex-col">
            <MailCard
              date={deliveryWindow}
              status={mailStatus}
              description={program?.name}
              impressions={impressionCount}
            />
          </div>
        )}
      </div>
      <Tabs
        className="px-8"
        tabs={[
          {
            icon: getIconByName('map', selectedTab === SelectedTab.MAP, false),
            name: 'Map',
            onClick: () => onMapSelected(),
            isSelected: selectedTab === SelectedTab.MAP,
          },
          {
            icon: getIconByName('menu', selectedTab === SelectedTab.LIST, false),
            name: 'List',
            onClick: () => onListSelected(),
            isSelected: selectedTab === SelectedTab.LIST,
          },
        ]}
      />
    </div>
  );
};

export default DetailsHeaderMobileUI;
