import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { FullUIMenuRouteDefinition, UIMenuDefinition } from '../../routes/types';
import { Avatar } from '@platform/ui';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Portal } from '@platform/ui-helpers';
import { UserPermissions } from '@platform/types';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

interface NavProps {
  userName?: string;
  isSuperAdmin: boolean;
  isImpersonating: boolean;
  isSubscribed: boolean | null;
  hasCampaigns: boolean;
  hasPrograms: boolean;
  email?: string;
  permissions?: UserPermissions;
  primaryRoutes: FullUIMenuRouteDefinition[];
  secondaryRoutes: FullUIMenuRouteDefinition[];
  logout: () => void;
  stopImpersonating: () => void;
}

const isDisabled = (disabled: UIMenuDefinition['disabled'], props: NavProps) =>
  typeof disabled === 'boolean' ? disabled : typeof disabled === 'function' ? disabled(props) : false;

export function Nav(props: NavProps) {
  const { isSuperAdmin, userName, primaryRoutes, secondaryRoutes, email, logout } = props;
  return (
    <nav className="mt-5 flex-auto flex flex-col overflow-y-auto" aria-label="Sidebar">
      <div className="space-y-1 flex-grow-0">
        {primaryRoutes
          .filter(({ menu: { disabled } }) => !isDisabled(disabled, props))
          .map(({ name, route, menu: { disabled, classes, icon: Icon } }) => (
            <NavLink
              key={name}
              to={route}
              className={({ isActive }) =>
                `${
                  isActive ? 'bg-blue-800 text-white' : 'text-blue-100'
                } hover:text-white hover:bg-blue-600 group flex items-center py-2 text-sm leading-6 font-medium rounded-md ${classes}`
              }
            >
              <Icon className="ml-2 mr-4 flex-shrink-0 h-6 w-6 text-blue-200" aria-hidden="true" />
              {name}
            </NavLink>
          ))}
      </div>
      <div className="flex-grow-0 space-y-1 mx-2 mt-6 focus:border-none">
        <h3 className="mt-4 text-xs font-semibold text-blue-400 uppercase tracking-wider" id="projects-headline">
          ACCOUNT SETTINGS
        </h3>
        <div className="space-y-1" role="group" aria-labelledby="projects-headline">
          {secondaryRoutes
            .filter(({ menu: { disabled } }) => !isDisabled(disabled, props))
            .map(({ name, route }) => (
              <NavLink
                key={name}
                to={route}
                className={({ isActive }) =>
                  `${
                    isActive ? 'bg-blue-800 text-white' : 'text-blue-100'
                  } group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-white hover:bg-blue-600'
                }`
                }
              >
                <span className="truncate">{name}</span>
              </NavLink>
            ))}
        </div>
      </div>
      <div className="flex-grow mt-6 ">
        <NavLink
          to={Portal.getRouteName('/getting-started')}
          className="flex space-x-1 bg-blue-800 rounded-md px-2 py-4 hover:bg-blue-900 hover:cursor-pointer"
        >
          <SparklesIcon className="text-yellow-400 shrink-0 h-[24px] w-[24px]" />
          <div className="flex flex-col text-white">
            <div className="font-medium text-sm">Finish Your Onboarding</div>
            <div className="font-normal text-xs">Complete your account setup and learn how to start scouting!</div>
          </div>
        </NavLink>
      </div>
      <Menu as="div" className="mb-3 mt-5 relative inline-block text-left">
        <div>
          <Menu.Button className="group w-full bg-blue-700 rounded-md px-3.5 py-2 text-sm text-left font-medium text-white hover:bg-blue-800 focus:outline-none">
            <span className="flex w-full justify-between items-center">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <Avatar size="large" avatar={{ name: userName }} />
                <span className="flex-1 flex flex-col min-w-0">
                  {userName && <span className="text-white text-sm font-medium truncate"></span>}
                  {isSuperAdmin && <span className="text-blue-100 text-sm truncate">Super Admin</span>}
                  <span className="text-blue-300 text-sm group-hover:text-blue-100 truncate">{email}</span>
                </span>
              </span>
              <ChevronUpDownIcon
                className="flex-shrink-0 h-5 w-5 text-blue-300 group-hover:text-blue-100"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 mx-3 origin-bottom absolute right-0 left-0 bottom-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <div className="p-1">
              {props.isImpersonating && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={props.stopImpersonating}
                      className={classNames(
                        active ? 'bg-blue-50 text-blue-500' : 'text-blue-500',
                        'block px-4 py-2 text-sm text-left w-full'
                      )}
                    >
                      Exit Birdseye Mode
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={logout}
                    className={classNames(
                      active ? 'bg-red-50 text-red-500' : 'text-red-500',
                      'block px-4 py-2 text-sm text-left w-full'
                    )}
                  >
                    Sign Out
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="p-1">
              <Menu.Button className="group rounded-md p-2 w-full text-sm text-left font-medium text-white hover:bg-gray-100 focus:outline-none">
                <span className="flex w-full justify-between items-center">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    <Avatar size="large" avatar={{ name: userName }} />
                    <span className="flex-1 flex flex-col min-w-0">
                      {userName && <span className="text-gray-700 text-sm font-medium truncate">{userName}</span>}
                      <span className="text-gray-500 text-sm truncate">{email}</span>
                    </span>
                  </span>
                </span>
              </Menu.Button>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </nav>
  );
}
