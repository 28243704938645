"use strict";
exports.__esModule = true;
exports.QualificationStatus = exports.MailRemovedReasons = void 0;
var MailRemovedReasons;
(function (MailRemovedReasons) {
    MailRemovedReasons["BOUNCED"] = "bounced";
    MailRemovedReasons["CONVERTED"] = "converted";
    MailRemovedReasons["OPT_OUT"] = "opt_out";
    MailRemovedReasons["DISQUALIFIED"] = "disqualified";
    MailRemovedReasons["STOPPED"] = "stopped";
    MailRemovedReasons["FAILED_VERIFICATION"] = "failed_verification";
    MailRemovedReasons["ORDER_FAILED"] = "order_failed";
})(MailRemovedReasons = exports.MailRemovedReasons || (exports.MailRemovedReasons = {}));
var QualificationStatus;
(function (QualificationStatus) {
    QualificationStatus["QUALIFIED"] = "qualified";
    QualificationStatus["DISQUALIFIED"] = "disqualified";
    QualificationStatus["UNQUALIFIED"] = "unqualified";
})(QualificationStatus = exports.QualificationStatus || (exports.QualificationStatus = {}));
