"use strict";
// We no longer use Cole for anything but these types represent structured Cole data that is saved in our database
exports.__esModule = true;
exports.isTruthy = exports.NetWorthRanges = void 0;
exports.NetWorthRanges = {
    A: 'Less than $1',
    B: '$1 - $4,999',
    C: '$5,000 - $9,999',
    D: '$10,000 - $24,999',
    E: '$25,000 - $49,999',
    F: '$50,000 - $99,999',
    G: '$100,000 - $249,999',
    H: '$250,000 - $499,999',
    I: 'Greater than $499,999'
};
function isTruthy(value) {
    return value === 'Y' || value === 'Yes' || value === '1' || value === 'True' || value === true;
}
exports.isTruthy = isTruthy;
