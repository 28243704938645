"use strict";
exports.__esModule = true;
exports.assertUnreachableSafe = exports.assertNotNever = exports.assertUnreachable = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
function assertUnreachable(x) {
    var error = "Didn't expect to get here ".concat(x);
    console.error(error);
    throw new Error(error);
}
exports.assertUnreachable = assertUnreachable;
function assertNotNever(x) {
    if (x === undefined) {
        var error = "Expected a value, but got undefined";
        console.error(error);
        throw new Error(error);
    }
    return x;
}
exports.assertNotNever = assertNotNever;
function assertUnreachableSafe(x) {
    console.error("Didn't expect to get here!! ".concat(x));
    return undefined;
}
exports.assertUnreachableSafe = assertUnreachableSafe;
