import { WithAuth0Props } from '@auth0/auth0-react';
import { UserPermissions } from '@platform/types';
import { useMemo } from 'react';
import { getPrimaryRoutes, getSecondaryRoutes } from '../../routes';
import { Nav } from './Nav';
import { CompanySelectList } from './company-select-list';
import { CompanySelectSearch } from './company-select-search';

export interface DesktopMenuProps extends WithAuth0Props {
  name: string;
  isSuperAdmin: boolean;
  companyName?: string;
  hasCampaigns: boolean;
  hasPrograms: boolean;
  externalId?: string;
  logout: () => void;
  isImpersonating: boolean;
  isSubscribed: boolean | null;
  stopImpersonating: () => void;
  permissions?: UserPermissions;
}

export function DesktopMenu({
  isSuperAdmin,
  isImpersonating,
  isSubscribed,
  hasPrograms,
  name,
  externalId,
  auth0,
  companyName,
  logout,
  hasCampaigns,
  stopImpersonating,
  permissions,
}: DesktopMenuProps) {
  // Wait until we have the company loaded before determining if we're super admin or not
  const Select = useMemo(
    () => (!externalId || !companyName ? null : isSuperAdmin ? CompanySelectSearch : CompanySelectList),
    [companyName, externalId, isSuperAdmin]
  );

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col flex-grow bg-blue-700 pt-2 pb-0 px-2 overflow-y-auto">
          {!!Select && (
            <Select companyName={companyName} externalId={externalId} getAccessToken={auth0.getAccessTokenSilently} />
          )}
          <Nav
            email={auth0.user?.email}
            userName={name}
            isSuperAdmin={isSuperAdmin}
            isImpersonating={isImpersonating}
            isSubscribed={isSubscribed}
            hasCampaigns={hasCampaigns}
            hasPrograms={hasPrograms}
            primaryRoutes={getPrimaryRoutes()}
            secondaryRoutes={getSecondaryRoutes()}
            logout={logout}
            stopImpersonating={stopImpersonating}
            permissions={permissions}
          />
        </div>
      </div>
    </div>
  );
}
