import { ProgramAPI } from '@platform/api';
import { buildAPIUrl } from '@platform/helpers';
import { CampaignMedium } from '@platform/types';
import { Spinner } from '@platform/ui';
import { useState } from 'react';
import './programsettings.css';

export const ProgramPreview = (props: {
  medium: CampaignMedium;
  programExternalId: string;
  sendoutIdx: number;
  side: 'front' | 'back';
  programApiUrl: string;
}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div className={`${props.medium} wrap`}>
      {!loaded && (
        <div className="pt-[65px] text-center">
          <Spinner size="large" variant="default" />
        </div>
      )}
      <iframe
        title={`Drip ${props.sendoutIdx + 1} Preview Front`}
        style={{ opacity: loaded ? 1 : 0 }}
        className={`${props.medium} frame`}
        onLoad={() => setLoaded(true)}
        src={
          props.medium === CampaignMedium.POSTCARD
            ? buildAPIUrl(props.programApiUrl, {
                route: ProgramAPI.Public.API['GET /program/:id/preview/postcard/:sendout/:side'],
                params: { id: props.programExternalId, sendout: props.sendoutIdx.toString(), side: props.side },
              })
            : props.medium === CampaignMedium.NOTE
            ? buildAPIUrl(props.programApiUrl, {
                route: ProgramAPI.Public.API['GET /program/:id/preview/note/:side'],
                params: { id: props.programExternalId, side: props.side },
              })
            : ''
        }
      />
    </div>
  );
};
